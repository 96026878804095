import Footer from "./Footer.js";
import Header from "./Header.js";
import Content from "./Content.js";
import React, {useState} from "react";

function Body (props) {
    const [activeTab, setActiveTab] = useState("home")
    
    return (
<div>
    <Header isMobile={props.isMobile} activeTab={activeTab} setActiveTab={setActiveTab}/>
    <Content/>
    <Footer isMobile={props.isMobile} activeTab={activeTab} setActiveTab={setActiveTab}/>
</div>

    )

}

export default Body;