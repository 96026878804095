import './App.css';
import React, { Component } from "react";
import Body from "./components/Body.js";
import background from "./assets/background.png";

class App extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 600;
    const imageClassName = (isMobile) ? "MobileImg" : "Img";  
    const className = (isMobile) ? "AppMobile" : "App";

      return (
        <div className={className}>
          <div className="body">
          <img className={imageClassName} src={background} alt="dark red marble like swirls"/>
          <Body isMobile={isMobile}/>
        </div>
        </div>
      );
}}

export default App;
