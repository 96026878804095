import './Content.css'
import React from "react";

function Content (props) {
    
    return (
    <div>
        <div className="whiteLine"></div>
        <p className="bodyText">
            High end restaurants need to be utilizing high tech software solutions to connect with their patrons in this increasingly digital age.
        </p>
        <div className="whiteLine"></div>
        <p className="bodyText">
            Elevé blends seamlessly into the fine dining experience, helping patrons form a stronger connection with what they are tasting, and the story behind the food.
        </p>
        <div className="whiteLine"></div>
        <p className="bodyText moreDetails">
            demo and more info coming soon!
        </p>
    </div>

    )

}

export default Content;