import './Footer.css';
import React from "react";


function Footer(props) {
    
    return (
    <div className="footer">
   </div>
    )
}
export default Footer;