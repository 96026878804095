import './Header.css';
import React from "react";

function Header(props) {

    return (
    <div className="header">
        <p className="eleveText">elevé</p>
    </div>
    )
}
export default Header;